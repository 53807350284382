import React, { useEffect, useRef, useState } from "react"
import { observer } from "mobx-react";
import { Autocomplete, Box, Button, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Skeleton, Switch, Tab, Tabs, TextField, Typography } from "@mui/material";
import Layout from "../../components/shared-layout";
import useStores from "../../hooks/use-stores";
import { ApiDataListType, ApiDataResponseListLengthCheck, GetCollegeId, PAGE_SIZE, TraineeshipStatus, TraineeshipTrainerStatus } from "../../constants/options";
import VmButton from "../../components/shared-button";
import VmTable from "../../components/shared-table";
import VmModal from "../../components/shared-modal";
import { t } from "i18next";
import ContentLayout from "../../components/shared-content-layout";
import { ITEM_PERFECT_INLINED, TD_FIRST, TD_LAST, TD_NORMAL, THEME_BUTTON } from "../../constants/style";
import { Link, navigate } from "gatsby";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import TabPanel from "../../components/shared-tab-panel";
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import SlideDown from "react-slidedown";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { DateUtils } from "../../utilities/date";
import Heading from "../../components/shared-heading";
import VmVisitSlideDownCard from "../../components/shared-VisitSlideDownCard";
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';
import IosShareIcon from '@mui/icons-material/IosShare';
import { OrgCodeForUSI } from "../../constants/settings";
import moment from "moment";

function TabProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TraineeshipOverviewPage = observer(({ location }) => {
  const { rootStore, studentStore, traineeshipStore, userStore } = useStores();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  // Filter Student
  const [filterStartDate, setFilterStartDate] = useState<string>(``);
  const [filterEndDate, setFilterEndDate] = useState<string>(``);
  const [filterSearchKey, setFilterSearchKey] = useState<string>(location.state && location.state.filterSearchKey ? location.state.filterSearchKey : "");
  const [filterActiveStatus, setFilterActiveStatus] = useState<string>("All");
  const [filterUSIVerified, setFilterUSIVerified] = useState<string>("All");
  // Determine is update or create
  const [targetStudent, setTargetStudent] = useState<any>();
  const [title, setTitle] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [middleName, setMiddleName] = useState<string>("");
  const [birthDate, setBirthDate] = useState<string>("");
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [oldPassword, setOldPassword] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [cPassword, setCPassword] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [formId, setFormId] = useState<number>(0);
  const [currentStudent, setCurrentStudent] = useState<any>(null);
  const [showCreateFormModal, setShowCreateFormModal] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState<number>(0);
  const [fold, setFold] = useState<number>(-1);
  const [trainerList, setTrainerList] = useState<any>([]);
  const [trainerId, setTrainerId] = useState<number>(0);
  const [contractStartDate, setContractStartDate] = useState<string>("");
  const [contractEndDate, setContractEndDate] = useState<string>("");
  const [tcidNumber, setTcidNumber] = useState<string>("");
  const [contractNo, setContractNo] = useState<string>("");
  const encryptedPassword = "ENCRYPTEDPASSWORD";
  const [visitTab, setVisitTab] = useState<boolean>(false);
  const [currentTraineeship, setCurrentTraineeship] = useState<any>([]);
  const [visitFold, setVisitFold] = useState<number>(0);
  const [startDate, setStartDate] = useState<any>(new Date(
    new Date().setMonth(new Date().getMonth() - 3)).toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState<any>(new Date
    (new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]);
  const [enrolmentNumber, setEnrolmentNumber] = useState<string>("");
  const [studentIdNumber, setStudentIdNumber] = useState<string>("");
  const [USI, setUSI] = useState<string>("");
  const [completeTraineeshipResult, setCompleteTraineeshipResult] = useState<string>("")
  const [preventLoading, setPreventLoading] = useState<boolean>(true);
  const [contractExtensionTime, setContractExtensionTime] = useState<string>("");
  const contentRef = useRef(null);

  useEffect(() => {
    studentStore.getStudentProfileList(PAGE_SIZE, location.state && location.state.inputPage
      ? (location.state.inputPage - 1) * PAGE_SIZE : 0, { name: location.state && location.state.filterSearchKey ? location.state.filterSearchKey : "" })
      .then((res) => {
        if (location.state && location.state.inputPage && location.state.traineeId) {
          setPage(location.state.inputPage);
          onRequestStudentTraineeshipData(location.state.traineeId)
          setCurrentStudent(res.data.find(s => s.id == location.state.traineeId));
        }
        setPreventLoading(false);
      });
  }, [location.state]);

  useEffect(() => {
    const delayDebouncer = setTimeout(() => {
      if (!preventLoading) {
        studentStore.studentProfileList.data.length == 0;
        studentStore.getStudentProfileList(PAGE_SIZE, 0, {
          name: filterSearchKey,
          fromDate: filterStartDate,
          toDate: filterEndDate,
          isActive: filterActiveStatus === "All" ? null : filterActiveStatus,
          isUSIConfirmed: filterUSIVerified === "All" ? null : filterUSIVerified,
        });
        setPage(1);
      }
    }, 600);
    return () => clearTimeout(delayDebouncer);
  }, [filterSearchKey, filterStartDate, filterEndDate]);

  useEffect(() => {
    // @ts-ignore
    if(currentStudent && contentRef.current) contentRef.current.scrollIntoView(({ behavior: 'smooth' }));
  }, [currentStudent]);

  const onActionToStudentByProcedure = () => {
    if (!targetStudent && password !== cPassword ||
      (targetStudent && password !== encryptedPassword && cPassword !== password)) {
      rootStore.notify(t('TWO_PASSWORDS_NOT_MATCH'), 'warning');
      return;
    };
    console.log(targetStudent && USI && USI !== "");

    if (!USI || USI === "" || (targetStudent && targetStudent.usi && targetStudent.usi !== "")) onActionStudentProfile();
    else {
      traineeshipStore.verifyUSI({
        // usi: USI,
        // firstName: firstName,
        // lastName: lastName,
        // dateOfBirth: birthDate,
        orgCode: OrgCodeForUSI,
        usi: USI,
        firstName: "Maryam",
        lastName: "Fredrick",
        dateOfBirth: "1966-05-25"
      }).then((res) => {
        if (!res) rootStore.notify("USI Verification Failed! Please check your input number and try again", "error");
        else {
          rootStore.notify(`USI Verified! ${targetStudent ? "Updating" : "Creating"} trainee profile`, "info");
          onActionStudentProfile();
        }
      }).catch(() => rootStore.notify("Verify failed, please try again later", "error"));
    }
  }

  const onActionStudentProfile = () => {
    let req: any = {
      id: 0,
      title,
      firstName,
      lastName,
      middleName,
      birthDate,
      emailAddress,
      username,
      usi: USI,
      collegeId: GetCollegeId(),
      password: password === encryptedPassword ? oldPassword : password,
      // if email address is changed, convert user status = false
      active: !targetStudent ? false : targetStudent.emailAddress != emailAddress ? false : targetStudent.active,
    }
    if (targetStudent) {
      req.id = targetStudent.id;
      studentStore.updateStudentProfile(req).then(() => {
        rootStore.notify(t('STUDENT_PROFILE') + t('UPDATED_B'), 'success');
        studentStore.getStudentProfileList(PAGE_SIZE, 0, {
          name: filterSearchKey,
          fromDate: filterStartDate,
          toDate: filterEndDate,
          isActive: filterActiveStatus === "All" ? null : filterActiveStatus,
          isUSIConfirmed: filterUSIVerified === "All" ? null : filterUSIVerified,
        });
        setCurrentStudent(null);
        handleCloseModal();
      }).catch(() => rootStore.notify(t('UPDATE_FAILED'), 'error'));
    } else {
      studentStore.createStudentProfile(req).then(() => {
        rootStore.notify(t('STUDENT_PROFILE') + t('CREATED_B'), 'success')
        studentStore.getStudentProfileList(PAGE_SIZE, 0, {
          name: filterSearchKey,
          fromDate: filterStartDate,
          toDate: filterEndDate,
          isActive: filterActiveStatus === "All" ? null : filterActiveStatus,
          isUSIConfirmed: filterUSIVerified === "All" ? null : filterUSIVerified,
        });
        handleCloseModal();
      }).catch(() => rootStore.notify('Create failed! This username might already be used, please try again!', 'error'));
    }
  }

  const handleShowModal = (id?: number) => {
    if (id) {
      let target = studentStore.studentProfileList.data.find(s => s.id == id);
      setTargetStudent(target);
      setTitle(target.title);
      setFirstName(target.firstName);
      setLastName(target.lastName);
      setMiddleName(target.middleName);
      setBirthDate(target.birthDate.split('T')[0]);
      setEmailAddress(target.emailAddress);
      setUsername(target.username);
      setOldPassword(target.password);
      setPassword(encryptedPassword);
      setUSI(target.usi);
    }
    setShowModal(true);
  }

  const handleCloseModal = () => {
    setShowModal(false);
    setShowDeleteModal(false);
    setTargetStudent(null);
    setTitle("");
    setFirstName("");
    setLastName("");
    setMiddleName("");
    setBirthDate("");
    setEmailAddress("");
    setUsername("");
    setCPassword("");
    setOldPassword("");
    setPassword("");
    setUSI("");
  }

  const handleDeleteModal = (id: number) => {
    setFormId(id);
    setShowDeleteModal(true);
  }

  const createNewEnrolForm = (trainee: any) => {
    traineeshipStore.createEnrollForm(trainee.id)
      .then((form: any) => {
        navigate("/traineeship/registration-form", {
          state: {
            formId: form,
            previewMode: false,
            firstName: trainee.firstName,
            lastName: trainee.lastName,
            middleName: trainee.middleName,
            email: trainee.emailAddress,
            birthDate: trainee.birthDate.split('T')[0],
            traineeId: trainee.id,
            usi: trainee.usi,
            inputPage: page,
          }
        })
      })
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  }
  const onChangeTraineeshipResult = (event: SelectChangeEvent) => {
    setCompleteTraineeshipResult(event.target.value as string);
  };
  const updateTCIDStatus = (id: number, status: boolean) => {
    traineeshipStore.updateTCIDStatus(id, status)
      .then(() => {
        rootStore.notify(`TCID ${status ? 'approved' : 'disapproved'} success!`, "success");
        studentStore.getStudentProfileById(currentStudent.id);
      }).catch(() => rootStore.notify(`TCID update failed! Please check your internet or contact network admin`, "error"));
  }

  const onChangeTrainer = (name: string) => {
    if (trainerList.length > 0) {
      let trainer = trainerList.find(s => name === s.firstName + " " + s.lastName);
      if (trainer) setTrainerId(trainer.id);
    }
  }

  const allocateTrainerToTraineeship = (traineeshipId: number) => {
    if (trainerId != 0) {
      traineeshipStore.assignTrainerToTraineeship(trainerId, traineeshipId)
        .then(() => {
          rootStore.notify(`Trainer & Assessor allocation success!`, "success");
          onRequestStudentTraineeshipData(currentStudent.id);
        }).catch(() => rootStore.notify(`Trainer & Assessor allocation success failed! Please check your internet or contact network admin`, "error"));
    }
  }

  const onRequestStudentTraineeshipData = (id: number) => {
    studentStore.getStudentProfileById(id)
      .then((res) => {
        setContractNo("");
        setTcidNumber("");
        setContractStartDate("");
        setContractEndDate("");
        setEnrolmentNumber("");
        setStudentIdNumber("");
        setCompleteTraineeshipResult("");
        if (res.traineeships.length > 0) {
          if (res.traineeships.traineeshipStatus == TraineeshipStatus.APPROVED)
            setContractEndDate(res.traineeship.idNavigation.studentContract.endDate.split('T')[0]);
          if (res.traineeships.trainerStatus != TraineeshipTrainerStatus.ACCEPTED) {
            traineeshipStore.getTrainerListByQualificationId(res.traineeships[0].qualificationId)
              .then((res) => setTrainerList(res));
            if (res.traineeships.trainerStatus != TraineeshipTrainerStatus.NOT_ALLOCATED) setTrainerId(0);
            else setTrainerId(res.traineeships.trainerId);
          } else setTrainerId(0);
        }
      });
  }

  const approveTraineeship = (traineeshipId: number) => {
    traineeshipStore.approveTraineeship(traineeshipId)
      .then(() => {
        onRequestStudentTraineeshipData(currentStudent.id);
        rootStore.notify("Traineeship approved!", "success");
      }).catch(() => rootStore.notify("Traineeship approve failed! Please check your internet or contact network admin", "error"));
  }

  const updateExtentionStatus = (extensionId: number, status: boolean) => {
    traineeshipStore.updateContractExtensionStatus(extensionId, status)
      .then(() => {
        onRequestStudentTraineeshipData(currentStudent.id);
        rootStore.notify(`Request ${status ? 'accepted' : 'rejected'!}`, "success");
      }).catch(() => rootStore.notify("Request update failed", "error"));
  }

  const updateContractInfo = (id: number, tcidApproved: boolean) => {
    traineeshipStore.updateContractInfo({
      formId: id,
      tcidNo: tcidNumber,
      tcidApproved: true,
      contractNo,
      contractStartDate,
      contractEndDate,
      enrolmentNumber,
      studentIdentificationNumber: studentIdNumber,
    }).then(() => {
      onRequestStudentTraineeshipData(currentStudent.id);
      rootStore.notify(`Contract status updated`, "success");
    }).catch(() => rootStore.notify("Contract update failed", "error"));
  }

  const onToggleFold = (id: number) => {
    if (visitFold === id) {
      setVisitFold(0)
    } else {
      let delayInMilliseconds = 500; //1 second
      userStore.setVisitTableLoading(true);
      setTimeout(function () {
        userStore.getChangeRequestListByVisitId(id);
      }, delayInMilliseconds);
      userStore.getChangeRequestListByVisitId(id);
      setVisitFold(id)
    }
  }

  const onToggleEditFold = (id: number) => {
    if (fold === id) setFold(-1)
    else setFold(id);
  }

  const onToggleTraineeshipEdit = (index: number, tShips: any) => {
    if (tShips.traineeshipStatus !== TraineeshipStatus.COMPLETED) {
      onToggleEditFold(index);
      setContractStartDate("");
      setContractEndDate("");
      setContractNo("");
      setTcidNumber("");
      setEnrolmentNumber("");
      setStudentIdNumber("");
      setContractExtensionTime("");
      setCompleteTraineeshipResult("");
      if (tShips.traineeshipStatus == TraineeshipStatus.APPROVED)
        setContractEndDate(tShips.idNavigation.studentContract.endDate.split('T')[0]);
      if (tShips.trainerStatus != TraineeshipTrainerStatus.ACCEPTED) {
        traineeshipStore.getTrainerListByQualificationId(tShips.qualificationId)
          .then((res) => setTrainerList(res));
        if (tShips.trainerStatus != TraineeshipTrainerStatus.NOT_ALLOCATED) setTrainerId(0);
        else setTrainerId(tShips.trainerId);
      } else setTrainerId(0);
    }
  }

  const onGenerateExcelFile = () => {
    traineeshipStore.generateTraineeEnrollOverviewExcel({ startDate, endDate: endDate + "T23:59:59" });
  }

  const onCompleteTraineeship = (id: any) => {
    traineeshipStore.completeTraineeship({ traineeshipId: id, result: completeTraineeshipResult })
      .then(() => {
        onToggleEditFold(-1);
        onRequestStudentTraineeshipData(currentStudent.id);
        rootStore.notify('Traineeship status updated', "success")
      }).catch(() => rootStore.notify("Traineeship status update failed, please try later", "error"));
  }
  const onChangeActiveStatus = (event: SelectChangeEvent) => {
    studentStore.studentProfileList.data.length == 0;
    setFilterActiveStatus(event.target.value as string);
    studentStore.getStudentProfileList(PAGE_SIZE, 0, {
      name: filterSearchKey,
      fromDate: filterStartDate,
      toDate: filterEndDate,
      isActive: (event.target.value as string) === "All" ? null : event.target.value as string,
      isUSIConfirmed: filterUSIVerified === "All" ? null : filterUSIVerified,
    });
    setPage(1);
  };
  const onChangeUSIVerified = (event: SelectChangeEvent) => {
    studentStore.studentProfileList.data.length == 0;
    setFilterUSIVerified(event.target.value as string);
    studentStore.getStudentProfileList(PAGE_SIZE, 0, {
      name: filterSearchKey,
      fromDate: filterStartDate,
      toDate: filterEndDate,
      isActive: filterActiveStatus === "All" ? null : filterActiveStatus,
      isUSIConfirmed: (event.target.value as string) === "All" ? null : event.target.value as string,
    });
    setPage(1);
  };
  const onChangeContractExtension = (event: SelectChangeEvent) => {
    setContractExtensionTime(event.target.value as string);
  };
  return (
    <ContentLayout
      pageName={t('View Enrollment Form by Trainee')}
      pageHeading={""}
      breadCrumb={[
        { label: t('TRAINEESHIP') + t('SETTING_B'), to: "" },
        { label: t('OVERVIEW'), to: "/traineeship/overview" }
      ]}
      buttonLabel={t('ADD_A') + t('STUDENT_PROFILE')}
      onClickAction={() => handleShowModal()}
      showHeadingButton={false}
    >
      {traineeshipStore.loading ?
        <div className="text-center mb-4"><Skeleton height={50} /></div>
        : <div className="flex mb-4" style={{ marginTop: -10 }}>
          <TextField
            value={startDate}
            label={t('START_DATE')}
            type="date"
            variant="outlined"
            size="small"
            sx={{ width: 200, marginRight: 2, background: 'white' }}
            onChange={(value) => setStartDate(value.target.value)}
            className="col-span-2"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            value={endDate}
            label={t('END_DATE')}
            type="date"
            variant="outlined"
            size="small"
            sx={{ width: 200, marginRight: 2, background: 'white' }}
            onChange={(value) => setEndDate(value.target.value)}
            className="col-span-2"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button
            variant="outlined"
            size="small"
            disabled={traineeshipStore.downloadExcel || new Date(startDate) > new Date(endDate)}
            onClick={onGenerateExcelFile}
          >
            {traineeshipStore.downloadExcel ? <CircularProgress />
              : <Box sx={ITEM_PERFECT_INLINED}>
                <IosShareIcon />
                <Typography marginLeft={0.5} variant="caption">Export Trainee Enroll Forms as Excel File</Typography></Box>}
          </Button>
        </div>}
      <div className={`flex justify-between mt-6`}
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          justifyContent: 'justify-between'
        }}>
        <Typography fontWeight={'bold'} marginBottom={3}
          className="lg:text-lg 2xl:text-2xl"
          sx={{ borderLeftWidth: 8, borderColor: 'black', height: 'fit-content', paddingLeft: 2 }}>
          View Enrollment Form by Trainee
        </Typography>
        <VmButton
          className="text-white px-4 py-2 mb-4 lg:text-sm 2xl:text-base"
          style={{ height: 'fit-content', backgroundColor: THEME_BUTTON }}
          onClick={() => handleShowModal()}
        >
          {t('ADD_A') + t('STUDENT_PROFILE')}
        </VmButton>
      </div>
      <Box sx={ITEM_PERFECT_INLINED} className="pb-4 border-b border-b-gray-400">
        <Button variant="outlined" onClick={() => {
          studentStore.studentProfileList.data.length == 0;
          studentStore.getStudentProfileList(PAGE_SIZE, 0, {});
          setPage(1);
        }}>Show All Trainees</Button>
        <p className="ml-2">Or</p>
      </Box>
      <Box sx={ITEM_PERFECT_INLINED} className="my-4">
        <TextField
          value={filterSearchKey}
          label={'Search Trainee Name'}
          variant="outlined"
          sx={{ width: 200, background: 'white' }}
          onChange={(value) => setFilterSearchKey(value.target.value)}
          size="small"
        />
        <TextField
          value={filterStartDate}
          label={t('Contract Start Date')}
          type="date"
          variant="outlined"
          size="small"
          sx={{ width: 200, marginX: 2, background: 'white' }}
          onChange={(value) => setFilterStartDate(value.target.value)}
          className="col-span-2"
          InputLabelProps={{ shrink: true, }}
        />
        <TextField
          value={filterEndDate}
          label={t('Contract End Date')}
          type="date"
          variant="outlined"
          size="small"
          sx={{ width: 200, marginRight: 2, background: 'white' }}
          onChange={(value) => setFilterEndDate(value.target.value)}
          className="col-span-2"
          InputLabelProps={{ shrink: true, }}
        />
        <FormControl size="small">
          <InputLabel>Account Status</InputLabel>
          <Select
            label={'Account Status'}
            sx={{ width: 150, marginRight: 2, background: 'white' }}
            value={filterActiveStatus}
            onChange={onChangeActiveStatus}
            size="small"
          >
            <MenuItem value={"All"}>All</MenuItem>
            <MenuItem value={"true"}>Email Validated</MenuItem>
            <MenuItem value={"false"}>No Verify yet</MenuItem>
          </Select>
        </FormControl>
        <FormControl size="small">
          <InputLabel>USI Status</InputLabel>
          <Select
            label={'USI Status'}
            sx={{ width: 150, marginRight: 2, background: 'white' }}
            value={filterUSIVerified}
            onChange={onChangeUSIVerified}
            size="small"
          >
            <MenuItem value={"All"}>All</MenuItem>
            <MenuItem value={"true"}>USI Verified</MenuItem>
            <MenuItem value={"false"}>No Verify yet</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <VmTable
        loading={studentStore.loading && studentStore.studentProfileList.data.length == 0}
        page={page}
        paginationCount={studentStore.studentProfileList.totalCount == 0 ? 1 : studentStore.studentProfileList.totalCount / PAGE_SIZE}
        onChangePagination={(event, pageNumber) => {
          setPage(pageNumber);
          studentStore.studentProfileList.data.length == 0;
          studentStore.getStudentProfileList(PAGE_SIZE, (pageNumber - 1) * PAGE_SIZE, {
            name: filterSearchKey,
            fromDate: filterStartDate,
            toDate: filterEndDate,
            isActive: filterActiveStatus === "All" ? null : filterActiveStatus,
            isUSIConfirmed: filterUSIVerified === "All" ? null : filterUSIVerified,
          });
        }}
        thead={["ID", t('NAME'), t('USERNAME'), t('BOD'), t('EMAIL'), 'Account Status', 'USI Status', t('ACTION')]}
        tbody={studentStore.studentProfileList.data.length > 0 ?
          studentStore.studentProfileList.data.map((student, index) => (
            <>
              <Box marginY={1} />
              <tr key={`student_${index}`}>
                <td className={TD_FIRST}>{index + 1}</td>
                <td className={TD_NORMAL}>{student.firstName + " " + student.lastName}</td>
                <td className={TD_NORMAL}>{student.username}</td>
                <td className={TD_NORMAL}>{DateUtils.onConvertDateFormat(student.birthDate.split('T')[0])}</td>
                <td className={TD_NORMAL}>{student.emailAddress}</td>
                <td className={TD_NORMAL}>
                  <Box sx={ITEM_PERFECT_INLINED} color={student.active ? "green" : "orange"} className="lg:text-lg 2xl:text-xl">
                    {student.active ? <CheckCircleOutlineOutlinedIcon fontSize="inherit" />
                      : <PendingOutlinedIcon color="inherit" fontSize="inherit" />}
                    <Typography ml={1} className="lg:text-sm 2xl:text-md">{student.active ? "Email Validated" : "Waiting for Student to Validate His/Her Email"}</Typography>
                  </Box></td>
                <td className={TD_NORMAL}>
                  <Box sx={ITEM_PERFECT_INLINED} color={student.usi && student.usi !== "" ? "green" : "orange"} className="lg:text-lg 2xl:text-xl">
                    {student.usi && student.usi !== "" ? <CheckCircleOutlineOutlinedIcon fontSize="inherit" />
                      : <PendingOutlinedIcon color="inherit" fontSize="inherit" />}
                    <Typography ml={1} className="lg:text-sm 2xl:text-md">{student.usi && student.usi !== "" ? "USI Validated" : "Not Yet"}</Typography>
                  </Box></td>
                <td className={TD_LAST}>
                  <Button
                    variant="outlined"
                    sx={{ marginRight: 2 }}
                    onClick={() => handleShowModal(student.id)}
                  >
                    {t('EDIT')}
                  </Button>
                  <Button
                    variant="outlined"
                    className="2xl:mt-0"
                    sx={{ marginRight: 2 }}
                    onClick={() => {
                      onRequestStudentTraineeshipData(student.id)
                      setCurrentStudent(student);
                      setTrainerId(0);
                      setContractStartDate("");
                      setContractEndDate("");
                      setContractNo("");
                      setTcidNumber("");
                      setEnrolmentNumber("");
                      setStudentIdNumber("");
                      setContractExtensionTime("");
                      setVisitTab(false);
                    }}
                  >
                    {t('VIEW')} Enrollment Form & Traineeship
                  </Button>
                  {!student.active && <Button disabled={traineeshipStore.resending} variant="outlined" sx={{ marginRight: 2, marginTop: 2 }} onClick={() => traineeshipStore.resendVerifyEmail(student.emailAddress).then(() => rootStore.notify('Resend email euccess', 'success')).catch(() => rootStore.notify('Resend email failed', 'error'))}>
                    {traineeshipStore.resending ? 'Sending Verification Email...' : 'Resend Verification Email'}
                  </Button>}
                </td>
              </tr>
              <Box marginY={1} />
            </>
          ))
          : <tr><td className="text-center py-4" colSpan={8}>{t('NO_RESULT_FOUND')}</td></tr>}
      />

      {currentStudent && <>
        <Box className="flex justify-center lg:mb-4 2xl:mb-2 lg:mt-10 2xl:mt-8">
          <Box className="bg-white rounded-lg px-4" sx={{ width: 'fit-content' }}>
            <Tabs value={tabValue} onChange={handleChange}>
              <Tab label="Enrollment Form List" {...TabProps(0)} />
              <Tab label="Traineeship List" {...TabProps(1)} />
            </Tabs>
          </Box>
        </Box>
        <span ref={contentRef}></span>
        <TabPanel value={tabValue} index={0}>
          <Box sx={ITEM_PERFECT_INLINED} className="justify-between mb-2">
            <Typography className="lg:text-lg 2xl:text-2xl" fontWeight={'bold'} marginBottom={2}
              sx={{ borderLeftWidth: 8, borderColor: 'black', height: 'fit-content', paddingLeft: 2 }}>
              {t('Enrollment Form List')}: {currentStudent.firstName + " " + currentStudent.lastName}
            </Typography>
            <VmButton
              className="text-white px-4 py-2 lg:text-sm 2xl:text-base"
              style={{ height: 'fit-content', backgroundColor: THEME_BUTTON }}
              onClick={() => setShowCreateFormModal(true)}
            >
              Create New Enrollment Form
            </VmButton>
          </Box>

          <VmTable
            loading={studentStore.loading}
            page={0}
            thead={["ID", "Form Status", "Contract Date", t('ACTION')]}
            tbody={studentStore.sProfile && studentStore.sProfile.studentEnrollForms.length > 0 ?
              studentStore.sProfile.studentEnrollForms
                .map((form: any, index: number) => (
                  <>
                    <Box marginY={1} />
                    <tr key={`form_${index}`}>
                      <td className={TD_FIRST}>{index + 1}</td>
                      <td className={TD_NORMAL}>
                        <Box sx={ITEM_PERFECT_INLINED} color={form.isSubmitted ? "green" : "orange"} className=" lg:text-md 2xl:text-lg">
                          {form.isSubmitted ? <>
                            <AssignmentTurnedInIcon color="inherit" fontSize="inherit" />
                            <Typography marginLeft={0.5} className="lg:text-sm 2xl:text-md">Submitted</Typography>
                          </> : <><PendingActionsIcon color="inherit" fontSize="inherit" />
                            <Typography marginLeft={0.5} className="lg:text-sm 2xl:text-md">Not Submitted Yet</Typography>
                          </>}
                        </Box>
                      </td>
                      <td className={TD_NORMAL}>{(form.studentContract && form.studentContract.startDate && form.studentContract.endDate) &&
                        DateUtils.onConvertDateFormat(form.studentContract.startDate.split('T')[0]) + " to " + DateUtils.onConvertDateFormat(form.studentContract.endDate.split('T')[0])}</td>
                      <td className={TD_LAST}>
                        <Link to="/traineeship/registration-form"
                          state={{
                            formId: form.id,
                            previewMode: form.isSubmitted,
                            firstName: studentStore.sProfile.firstName,
                            lastName: studentStore.sProfile.lastName,
                            middleName: studentStore.sProfile.middleName,
                            email: studentStore.sProfile.emailAddress,
                            birthDate: studentStore.sProfile.birthDate.split('T')[0],
                            traineeId: currentStudent.id,
                            usi: currentStudent.usi,
                            inputPage: page,
                          }}>
                          <Button variant="outlined" sx={{ marginRight: 2 }}>
                            View & Edit Form
                          </Button>
                        </Link>

                        <Button
                          variant="outlined"
                          color="error"
                          disabled={form.isSubmitted}
                          onClick={() => handleDeleteModal(form.id)}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                    <Box marginY={1} />
                  </>
                ))
              : <tr><td className="text-center py-4" colSpan={6}>Not enroll form found in this trainee</td></tr>}
          />
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <Box sx={ITEM_PERFECT_INLINED} className="justify-between mb-2">
            <Typography className="lg:text-lg 2xl:text-2xl" fontWeight={'bold'} marginBottom={2}
              sx={{ borderLeftWidth: 8, borderColor: 'black', height: 'fit-content', paddingLeft: 2 }}>
              {t('Traineeship List')}: {currentStudent.firstName + " " + currentStudent.lastName}
            </Typography>
          </Box>

          <VmTable
            loading={studentStore.loading}
            page={0}
            thead={["ID", "Qualification", "Trainer Status", "Contract Duration", "TCID Status", "Traineeship Status", "Action"]}
            tbody={studentStore.sProfile && studentStore.sProfile.traineeships.length > 0 ?
              studentStore.sProfile.traineeships
                .map((tShips: any, index: number) => (
                  <>
                    <Box marginY={1} />
                    <tr key={`tship_form_${index}`} style={{ cursor: "pointer" }}>
                      <td className={TD_FIRST} onClick={() => onToggleTraineeshipEdit(index, tShips)}>{index + 1}</td>
                      <td className={TD_NORMAL} style={{ width: "300px" }} onClick={() => onToggleTraineeshipEdit(index, tShips)}>{tShips.qualification.code + " " + tShips.qualification.name}</td>
                      {/* <td className={TD_NORMAL}>{tShips.organisation.name}</td> */}
                      <td className={TD_NORMAL} onClick={() => onToggleTraineeshipEdit(index, tShips)}>
                        <Box sx={ITEM_PERFECT_INLINED} className="lg:text-lg 2xl:text-xl" color={tShips.trainerStatus != TraineeshipTrainerStatus.ACCEPTED ? "orange" : "green"}>
                          {tShips.trainerStatus == TraineeshipTrainerStatus.ACCEPTED ? <CheckCircleOutlineOutlinedIcon fontSize="inherit" />
                            : tShips.trainerStatus == TraineeshipTrainerStatus.REJECTED ? <ErrorOutlineOutlinedIcon fontSize="inherit" />
                              : <PendingOutlinedIcon color="inherit" fontSize="inherit" />}
                          <Typography marginLeft={0.5} className="lg:text-sm 2xl:text-md">{tShips.trainerStatus}</Typography>
                        </Box>
                      </td>
                      <td className={TD_NORMAL} onClick={() => onToggleTraineeshipEdit(index, tShips)}>
                        <Box sx={ITEM_PERFECT_INLINED} className="lg:text-lg 2xl:text-xl">
                          <Box color={tShips.idNavigation.studentContract.startDate && tShips.idNavigation.studentContract.endDate ? "green" : "orange"} >
                            {tShips.idNavigation.studentContract.startDate && tShips.idNavigation.studentContract.endDate ? <CheckCircleOutlineOutlinedIcon fontSize="inherit" />
                              : <PendingOutlinedIcon color="inherit" fontSize="inherit" />}
                          </Box>
                          <Typography marginLeft={0.5} className="lg:text-sm 2xl:text-md" color={tShips.idNavigation.studentContract.startDate && tShips.idNavigation.studentContract.endDate ? "green" : "orange"}>
                            {tShips.idNavigation.studentContract.startDate && tShips.idNavigation.studentContract.endDate ?
                              DateUtils.onConvertDateFormat(tShips.idNavigation.studentContract.startDate.split('T')[0]) + " - " + 
                              moment(tShips.idNavigation.studentContract.finalDate).format('DD/MM/YYYY') : "Not Issued"}
                          </Typography>
                        </Box>
                      </td>
                      <td className={TD_NORMAL} onClick={() => onToggleTraineeshipEdit(index, tShips)}>
                        <Box sx={ITEM_PERFECT_INLINED} className="lg:text-lg 2xl:text-xl">
                          <Box color={tShips.idNavigation.studentTcid.isApproved ? "green" : "orange"} >
                            {tShips.idNavigation.studentTcid.isApproved ? <CheckCircleOutlineOutlinedIcon fontSize="inherit" />
                              : <PendingOutlinedIcon color="inherit" fontSize="inherit" />}
                          </Box>
                          <Typography marginLeft={0.5} className="lg:text-sm 2xl:text-md" color={!tShips.idNavigation.studentTcid.isApproved ? "orange" : "green"}>
                            {tShips.idNavigation.studentTcid.isApproved ? "Approved" : "Not Approved"}
                          </Typography>
                        </Box>
                      </td>
                      <td className={TD_NORMAL} onClick={() => onToggleTraineeshipEdit(index, tShips)}>
                        <Box sx={ITEM_PERFECT_INLINED} className="lg:text-lg 2xl:text-xl">
                          <Box color={tShips.traineeshipStatus != TraineeshipStatus.PENDING ? "green" : "orange"} >
                            {tShips.traineeshipStatus != TraineeshipStatus.PENDING ? <CheckCircleOutlineOutlinedIcon fontSize="inherit" />
                              : <PendingOutlinedIcon color="inherit" fontSize="inherit" />}
                          </Box>
                          <Typography marginLeft={0.5} className="lg:text-sm 2xl:text-md" color={tShips.traineeshipStatus != TraineeshipStatus.PENDING ? "green" : "orange"}>
                            {tShips.traineeshipStatus}
                          </Typography>
                        </Box>
                        {tShips.traineeshipStatus == TraineeshipStatus.COMPLETED && <p>Result: {tShips.result}</p>}
                      </td>
                      <td className={TD_LAST}>
                        {/* {tShips.traineeshipStatus == TraineeshipStatus.COMPLETED &&
                          <Button
                            variant="outlined"
                            sx={{ marginRight: 1 }}
                            onClick={() => {
                              onToggleEditFold(index);
                              setContractStartDate("");
                              setContractEndDate("");
                              setContractNo("");
                              setTcidNumber("");
                              setEnrolmentNumber("");
                              setStudentIdNumber("");
                              if (tShips.traineeshipStatus == TraineeshipStatus.APPROVED)
                                setContractEndDate(tShips.idNavigation.studentContract.endDate.split('T')[0]);
                              if (tShips.trainerStatus != TraineeshipTrainerStatus.ACCEPTED) {
                                traineeshipStore.getTrainerListByQualificationId(tShips.qualificationId)
                                  .then((res) => setTrainerList(res));
                                if (tShips.trainerStatus != TraineeshipTrainerStatus.NOT_ALLOCATED) setTrainerId(0);
                                else setTrainerId(tShips.trainerId);
                              } else setTrainerId(0);
                            }}
                          >
                            UPDATE
                          </Button>
                            : 
                          <Box sx={ITEM_PERFECT_INLINED}>
                            <Box color={"green"} className="lg:text-lg 2xl:text-xl"><CheckCircleOutlineOutlinedIcon fontSize="inherit" /></Box>
                            <Typography className="lg:text-sm 2xl:text-md" marginLeft={0.5} color={"green"}>{tShips.traineeshipStatus}</Typography>
                          </Box>} */}
                        <Button
                          variant="outlined"
                          disabled={tShips.traineeshipStatus !== TraineeshipStatus.APPROVED && tShips.traineeshipStatus !== TraineeshipStatus.COMPLETED}
                          onClick={() => {
                            setVisitTab(true);
                            setCurrentTraineeship(tShips);
                            studentStore.getVisitListByTraineeshipId(tShips.id);
                          }}>
                          Visit List
                        </Button>
                      </td>
                    </tr>
                    {(tShips.idNavigation.studentContract.studentContractExtensions.length > 0 &&
                      tShips.idNavigation.studentContract.studentContractExtensions.filter(e => e.isApproved == null).length > 0) &&
                      <tr className="mt-4"><td colSpan={7} className="bg-white rounded-xl text-red-400 text-center py-2">
                        {tShips.idNavigation.studentContract.studentContractExtensions.filter(e => e.isApproved == null).length} extension request(s) is pending for approve!
                      </td></tr>}
                    <tr>
                      <td colSpan={7}>
                        <SlideDown closed={fold != index} className="w-full my-3">
                          {tShips.traineeshipStatus == TraineeshipStatus.PENDING ?
                            <div className="grid grid-cols-3 gap-4">
                              {/* Allocate Trainer */}
                              <Box textAlign={"center"} className={`bg-white rounded-xl p-4 border-2 ${tShips.trainerStatus != TraineeshipTrainerStatus.ACCEPTED ? 'border-orange-400' : 'border-green-400'}`}>
                                <Box sx={ITEM_PERFECT_INLINED} className="justify-center mb-4">
                                  <Typography className="lg:text-md 2xl:text-lg">Step 1. Allocate Trainer </Typography>
                                </Box>
                                <Box sx={ITEM_PERFECT_INLINED} className="justify-center mb-4 lg:text-lg 2xl:text-xl" color={tShips.trainerStatus != TraineeshipTrainerStatus.ACCEPTED ? "orange" : "green"}>
                                  {tShips.trainerStatus == TraineeshipTrainerStatus.ACCEPTED ? <CheckCircleOutlineOutlinedIcon fontSize="inherit" />
                                    : tShips.trainerStatus == TraineeshipTrainerStatus.REJECTED ? <ErrorOutlineOutlinedIcon fontSize="inherit" />
                                      : <PendingOutlinedIcon color="inherit" fontSize="inherit" />}
                                  <Typography marginLeft={0.5} className="lg:text-sm 2xl:text-md">{tShips.trainerStatus}</Typography>
                                </Box>
                                {tShips.trainerId != 0 && <>
                                  <Typography marginBottom={2}>Recently assigned trainer: {tShips.trainer && tShips.trainer.firstName + " " + tShips.trainer.lastName}</Typography>
                                </>}
                                {tShips.trainerStatus != TraineeshipTrainerStatus.ACCEPTED &&
                                  <>
                                    {traineeshipStore.loading ? <Skeleton width={"100%"} />
                                      : trainerList.length == 0 ? <Typography>No Trainer & Assessors found in this qualification</Typography>
                                        : <Autocomplete
                                          disablePortal
                                          id="combo-box-demo"
                                          value={trainerList.find(s => s.id == trainerId) ? trainerList.find(s => s.id == trainerId).firstName + " " + trainerList.find(s => s.id == trainerId).lastName : ""}
                                          options={trainerList.map((s) => ({ label: s.firstName + " " + s.lastName, id: s.id }))}
                                          sx={{ width: '100%', marginBottom: 2 }}
                                          onInputChange={(event, newInputValue) => onChangeTrainer(newInputValue)}
                                          renderInput={(params) =>
                                            <TextField  {...params} label={"Trainer & Assessor"} />}
                                        />}
                                    <Box className="flex justify-end mt-4">
                                      <Button variant="outlined" component="label" size="small" disabled={trainerId == 0}
                                        onClick={() => allocateTrainerToTraineeship(tShips.id)}>
                                        Allocate Trainer
                                      </Button>
                                    </Box>
                                  </>
                                }
                              </Box>

                              {/* Contract & TCID */}
                              <Box textAlign={"center"} className={`bg-white rounded-xl p-4 border-2 
                              ${!tShips.idNavigation.studentTcid.isApproved || !tShips.idNavigation.studentContract.startDate || !tShips.idNavigation.studentContract.endDate || !tShips.idNavigation.studentContract.contractNumber
                                  || !tShips.idNavigation.studentTcid.enrolmentNumber || !tShips.idNavigation.studentTcid.studentIdentificationNumber ? 'border-orange-400' : 'border-green-400'}`}>
                                <Box sx={ITEM_PERFECT_INLINED} className="justify-center mb-4 lg:text-lg 2xl:text-xl">
                                  <Typography className="lg:text-md 2xl:text-lg">Step 2. Contract Status </Typography>
                                </Box>

                                <Box sx={ITEM_PERFECT_INLINED} className="mb-2 justify-between">
                                  <Box sx={ITEM_PERFECT_INLINED}>
                                    <Typography marginRight={1} className="lg:text-sm 2xl:text-md">TCID Status: </Typography>
                                    <Box color={tShips.idNavigation.studentTcid.isApproved ? "green" : "orange"} className="lg:text-lg 2xl:text-xl">
                                      {tShips.idNavigation.studentTcid.isApproved ? <CheckCircleOutlineOutlinedIcon fontSize="inherit" />
                                        : <PendingOutlinedIcon color="inherit" fontSize="inherit" />}
                                    </Box>
                                    <Typography marginLeft={0.5} color={!tShips.idNavigation.studentTcid.isApproved ? "orange" : "green"} className="lg:text-sm 2xl:text-md">
                                      {tShips.idNavigation.studentTcid.isApproved ? `#${tShips.idNavigation.studentTcid.tcid} (Approved)` : "Not Approved"}
                                    </Typography>
                                  </Box>
                                </Box>

                                <Box sx={ITEM_PERFECT_INLINED} className="mb-2 justify-between">
                                  <Box sx={ITEM_PERFECT_INLINED}>
                                    <Typography marginRight={1} className="lg:text-sm 2xl:text-md">Contract Duration: </Typography>
                                    <Box className="lg:text-lg 2xl:text-xl" color={tShips.idNavigation.studentContract.startDate && tShips.idNavigation.studentContract.endDate ? "green" : "orange"} >
                                      {tShips.idNavigation.studentContract.startDate && tShips.idNavigation.studentContract.endDate ? <CheckCircleOutlineOutlinedIcon fontSize="inherit" />
                                        : <PendingOutlinedIcon color="inherit" fontSize="inherit" />}
                                    </Box>
                                    <Typography className="lg:text-sm 2xl:text-md" marginLeft={0.5} color={tShips.idNavigation.studentContract.startDate && tShips.idNavigation.studentContract.endDate ? "green" : "orange"}>
                                      {tShips.idNavigation.studentContract.startDate && tShips.idNavigation.studentContract.endDate ?
                                        DateUtils.onConvertDateFormat(tShips.idNavigation.studentContract.startDate.split('T')[0]) + " - " + DateUtils.onConvertDateFormat(tShips.idNavigation.studentContract.endDate.split('T')[0]) : "Not Issued"}
                                    </Typography>
                                  </Box>
                                </Box>

                                <Box sx={ITEM_PERFECT_INLINED} className="mb-2">
                                  <Typography marginRight={1} className="lg:text-sm 2xl:text-md">Contract Number: </Typography>
                                  <Typography className="lg:text-sm 2xl:text-md" color={tShips.idNavigation.studentContract.contractNumber ? "green" : "orange"}>
                                    {tShips.idNavigation.studentContract.contractNumber ? "#" + tShips.idNavigation.studentContract.contractNumber : "Not Issued"}
                                  </Typography>
                                </Box>

                                <Box sx={ITEM_PERFECT_INLINED} className="mb-2">
                                  <Typography className="lg:text-sm 2xl:text-md" marginRight={1}>Enrollment Number: </Typography>
                                  <Typography className="lg:text-sm 2xl:text-md" color={tShips.idNavigation.studentTcid.enrolmentNumber ? "green" : "orange"}>
                                    {tShips.idNavigation.studentTcid.enrolmentNumber ? "#" + tShips.idNavigation.studentTcid.enrolmentNumber : "Not Issued"}
                                  </Typography>
                                </Box>

                                <Box sx={ITEM_PERFECT_INLINED} className="mb-2">
                                  <Typography className="lg:text-sm 2xl:text-md" marginRight={1}>Student ID Number: </Typography>
                                  <Typography className="lg:text-sm 2xl:text-md" color={tShips.idNavigation.studentTcid.studentIdentificationNumber ? "green" : "orange"}>
                                    {tShips.idNavigation.studentTcid.studentIdentificationNumber ? "#" + tShips.idNavigation.studentTcid.enrolmentNumber : "Not Issued"}
                                  </Typography>
                                </Box>

                                {(!tShips.idNavigation.studentContract.startDate || !tShips.idNavigation.studentContract.endDate || !tShips.idNavigation.studentContract.contractNumber
                                  || !tShips.idNavigation.studentTcid.enrolmentNumber || !tShips.idNavigation.studentTcid.studentIdentificationNumber) &&
                                  <>
                                    <Box className="grid grid-cols-2 gap-4">
                                      <TextField
                                        value={tcidNumber}
                                        label={"TCID Number"}
                                        variant="outlined"
                                        sx={{ width: "100%" }}
                                        onChange={(value) => setTcidNumber(value.target.value)}
                                      />
                                      <TextField
                                        value={contractNo}
                                        label={"Contract Number"}
                                        variant="outlined"
                                        sx={{ width: "100%" }}
                                        onChange={(value) => setContractNo(value.target.value)}
                                      />
                                      <TextField
                                        value={enrolmentNumber}
                                        label={"Enrolment Number"}
                                        variant="outlined"
                                        sx={{ width: "100%" }}
                                        onChange={(value) => setEnrolmentNumber(value.target.value)}
                                      />
                                      <TextField
                                        value={studentIdNumber}
                                        label={"Student Identification Number"}
                                        variant="outlined"
                                        sx={{ width: "100%" }}
                                        onChange={(value) => setStudentIdNumber(value.target.value)}
                                      />
                                      <TextField
                                        value={contractStartDate}
                                        label={"Start Date"}
                                        variant="outlined"
                                        sx={{ width: "100%" }}
                                        type="date"
                                        inputProps={{ maxLength: 4 }}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(value) => setContractStartDate(value.target.value)}
                                      />
                                      <TextField
                                        value={contractEndDate}
                                        label={"End Date"}
                                        variant="outlined"
                                        sx={{ width: "100%" }}
                                        type="date"
                                        inputProps={{ maxLength: 4 }}
                                        InputLabelProps={{ shrink: true }}
                                        disabled={contractStartDate === ""}
                                        InputProps={{ inputProps: { min: contractStartDate } }}
                                        onChange={(value) => setContractEndDate(value.target.value)}
                                      />
                                    </Box>
                                    {traineeshipStore.loading ? <CircularProgress className="mt-2" />
                                      :
                                      <Box sx={ITEM_PERFECT_INLINED} className="justify-end">
                                        <Button disabled={tcidNumber === "" || contractNo === "" || enrolmentNumber === "" || studentIdNumber === "" || contractStartDate === "" || contractEndDate === ""} variant="outlined" component="label" size="small" color="success" sx={{ marginTop: 2 }} onClick={() => updateContractInfo(tShips.id, tShips.idNavigation.studentTcid.isApproved)}>
                                          Update Contract Info
                                        </Button>
                                      </Box>
                                    }
                                  </>}
                              </Box>

                              {/* Approve Traineeship */}
                              <Box textAlign={"center"} className={`bg-white rounded-xl p-4 border-2 ${tShips.traineeshipStatus == TraineeshipStatus.PENDING ? 'border-orange-400' : 'border-green-400'}`}>
                                <Box sx={ITEM_PERFECT_INLINED} className="justify-center mb-4">
                                  <Typography className="lg:text-md 2xl:text-lg">Step 3. Approve Traineeship </Typography>
                                </Box>

                                <Box sx={ITEM_PERFECT_INLINED} className="justify-center mb-4">
                                  <Box className="lg:text-md 2xl:text-lg" color={tShips.traineeshipStatus != TraineeshipStatus.PENDING ? "green" : "orange"} >
                                    {tShips.traineeshipStatus != TraineeshipStatus.PENDING ? <CheckCircleOutlineOutlinedIcon fontSize="inherit" />
                                      : <PendingOutlinedIcon color="inherit" fontSize="inherit" />}
                                  </Box>
                                  <Typography className="lg:text-sm 2xl:text-md" marginLeft={0.5} color={tShips.traineeshipStatus != TraineeshipStatus.PENDING ? "green" : "orange"}>
                                    {tShips.traineeshipStatus}
                                  </Typography>
                                </Box>

                                {tShips.traineeshipStatus == TraineeshipStatus.PENDING &&
                                  <>
                                    <Button
                                      variant="outlined"
                                      component="label"
                                      size="small"
                                      color="success"
                                      sx={{ marginBottom: 1 }}
                                      disabled={!tShips.idNavigation.studentTcid.isApproved || !tShips.idNavigation.studentContract.startDate || !tShips.idNavigation.studentContract.endDate
                                        || !tShips.idNavigation.studentContract.contractNumber || tShips.trainerStatus != TraineeshipTrainerStatus.ACCEPTED}
                                      onClick={() => approveTraineeship(tShips.id)}>
                                      Approve Traineeship
                                    </Button>
                                    <br />
                                    <Typography variant="body1" color="red">TIP: You can only approve this traineeship after completed trainer allocation and contract/TCID validation</Typography>
                                  </>}
                              </Box>
                            </div>
                            : <div className="grid grid-cols-3 gap-4">
                              {/* Request Contract Extension */}
                              <Box textAlign={"center"} className={`bg-white rounded-xl p-4`}>
                                <Box sx={ITEM_PERFECT_INLINED} className="justify-center mb-4">
                                  <Typography className="lg:text-lg 2xl:text-xl">Request Contract Extension List</Typography>
                                </Box>
                                {tShips.idNavigation.studentContract.studentContractExtensions.length > 0 ?
                                  tShips.idNavigation.studentContract.studentContractExtensions.map((ext: any, i: number) => (
                                    <Box sx={ITEM_PERFECT_INLINED} className="justify-center mb-2">
                                      <Typography className="lg:text-sm 2xl:text-md">{i + 1}. {ext.startDate.split('T')[0] + " to " + ext.endDate.split('T')[0]}</Typography>
                                      {ext.isApproved == null ? <>
                                        <Button variant="outlined" sx={{ marginLeft: 2 }} color="success" onClick={() => updateExtentionStatus(ext.id, true)}>Accept</Button>
                                        <Button variant="outlined" sx={{ marginLeft: 1 }} color="error" onClick={() => updateExtentionStatus(ext.id, false)}>Reject</Button>
                                      </> : ext.isApproved ? <Box color={"green"} sx={ITEM_PERFECT_INLINED} marginLeft={2} className="lg:text-lg 2xl:text-xl"><CheckCircleOutlineOutlinedIcon fontSize="inherit" /><Typography className="lg:text-sm 2xl:text-md">Accepted</Typography></Box>
                                        : <Box color={"red"} sx={ITEM_PERFECT_INLINED} marginLeft={2} className="lg:text-lg 2xl:text-xl"><CancelOutlinedIcon fontSize="inherit" /><Typography className="lg:text-sm 2xl:text-md">Rejected</Typography></Box>}
                                    </Box>
                                  )) : <Typography className="lg:text-sm 2xl:text-md">No Extension Requested</Typography>}
                              </Box>
                              <Box textAlign={"center"} className={`bg-white rounded-xl p-4`}>
                                <Box sx={ITEM_PERFECT_INLINED} className="justify-center mb-4">
                                  <Typography className="lg:text-lg 2xl:text-xl">Request Contract Extension</Typography>
                                </Box>
                                <p className="mb-4">Original End Date: {moment(new Date(tShips.idNavigation.studentContract.finalDate)).format("DD/MM/YYYY")}</p>
                                <FormControl>
                                  <InputLabel>Extension Time</InputLabel>
                                  <Select
                                    label={'Extension Time'}
                                    sx={{ width: 200}}
                                    value={contractExtensionTime}
                                    onChange={onChangeContractExtension}
                                  >
                                    <MenuItem value={"1"}>1 Month Extension</MenuItem>
                                    <MenuItem value={"3"}>3 Months Extension</MenuItem>
                                    <MenuItem value={"6"}>6 Months Extension</MenuItem>
                                  </Select>
                                </FormControl>
                                <p className="my-4">End Date After Extension: {moment(new Date(tShips.idNavigation.studentContract.finalDate)).add(+contractExtensionTime, 'M').format("DD/MM/YYYY")}</p>

                                {/* <Box className="grid grid-cols-2 gap-4">
                                  <TextField
                                    value={tShips.idNavigation.studentContract.startDate.split('T')[0]}
                                    label={"Start Date"}
                                    variant="outlined"
                                    sx={{ width: "100%" }}
                                    type="date"
                                    inputProps={{ maxLength: 4 }}
                                    InputLabelProps={{ shrink: true }}
                                    disabled
                                  />
                                  <TextField
                                    value={contractEndDate}
                                    label={"End Date"}
                                    variant="outlined"
                                    sx={{ width: "100%" }}
                                    type="date"
                                    inputProps={{ maxLength: 4 }}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ inputProps: { min: tShips.idNavigation.studentContract.endDate.split('T')[0] } }}
                                    onChange={(value) => setContractEndDate(value.target.value)}
                                  />
                                </Box> */}
                                <Box className="flex justify-end mt-4">
                                  {traineeshipStore.requestingExtension ? <CircularProgress />
                                    :
                                    <Button disabled={contractExtensionTime === ""} variant="outlined" component="label" size="small" color="success" onClick={() => {
                                      traineeshipStore.createContractExtension({
                                        contractId: tShips.idNavigation.studentContract.id,
                                        startDate: tShips.idNavigation.studentContract.finalDate,
                                        endDate: moment(new Date(tShips.idNavigation.studentContract.finalDate)).add(+contractExtensionTime, 'M').format("YYYY-MM-DD HH:mm:ss"),
                                      }).then(() => {
                                        onRequestStudentTraineeshipData(currentStudent.id);
                                        rootStore.notify("Contract extension requested!", "success");
                                      }).catch(() => rootStore.notify("Contract extension request failed!", "error"));
                                    }}>
                                      Request Contract Extension
                                    </Button>}
                                </Box>
                              </Box>
                              {/* Complete Traineeship */}
                              <Box className={`bg-white rounded-xl p-4 text-center`}>
                                <Box sx={ITEM_PERFECT_INLINED} className="justify-center mb-4">
                                  <Typography className="lg:text-lg 2xl:text-xl">Complete Traineeship</Typography>
                                </Box>
                                <FormControl fullWidth>
                                  <InputLabel>Result</InputLabel>
                                  <Select
                                    label={'Result'}
                                    sx={{ width: '100%', marginBottom: 2 }}
                                    value={completeTraineeshipResult}
                                    onChange={onChangeTraineeshipResult}
                                  >
                                    <MenuItem value={"Competent"}>Competent</MenuItem>
                                    {/* <MenuItem value={"Incompetent"}>Incompetent</MenuItem> */}
                                  </Select>
                                </FormControl>
                                <Box>
                                  <Button
                                    variant="outlined"
                                    color="success"
                                    disabled={completeTraineeshipResult === ""}
                                    onClick={() => onCompleteTraineeship(tShips.id)}
                                  >
                                    Complete Traineeship
                                  </Button>
                                </Box>
                                <Typography variant="caption" textAlign={"center"} marginTop={2} color="red">TIP: Select a final result and click to mark this traineeship as complete for the trainee</Typography>
                              </Box>
                            </div>}
                        </SlideDown>
                      </td>

                    </tr>
                    <Box marginY={1} />
                  </>
                ))
              : <tr><td className="text-center py-4" colSpan={7}>Not traineeships found in this trainee</td></tr>}
          />
        </TabPanel>
      </>}

      {visitTab &&
        <>
          <Heading>
            {t('VISIT_LIST')}: {currentTraineeship.qualification.code + " " + currentTraineeship.qualification.name}
          </Heading>
          <Box sx={ITEM_PERFECT_INLINED} className="mb-2">
            <span className="bg-sky-400 text-sky-400 rounded-2xl text-white p-2 pt-1 mr-2" />
            <Typography>Trainer & Assesor Not Check In Yet</Typography>
            <span className="bg-orange-400 text-orange-400 rounded-2xl text-white p-2 pt-1 ml-4 mr-2" />
            <Typography> Check In By Trainer & Assesor</Typography>
            <span className="bg-green-400 text-green-400 rounded-2xl text-white p-2 pt-1 ml-4 mr-2" />
            <Typography> Check Out By Trainer & Assesor</Typography>
          </Box>
          <VmTable
            loading={studentStore.loadingVisit}
            thead={["ID", t('VISIT_NAME'), "Date", t('START_TIME'), t('END_TIME'), "Check In Time", "Check Out Time", "File Upload Status", "Check in " + t('STATUS')]}
            page={0}
            tbody={studentStore.visitListByTraineeship.map((visit: any, index: number) => (
              <>
                <Box sx={{ marginY: 1 }} />
                <tr key={`visit_${index}`} style={{ cursor: "pointer" }}>
                  <td className={TD_FIRST} onClick={() => onToggleFold(visit.id)}>{index + 1}</td>
                  <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)}>{visit.name}</td>
                  <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)}>{visit.finalStartTime.split('T')[0]}</td>
                  <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)}>{visit.finalStartTime.split('T')[1]}</td>
                  <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)}>{visit.finalEndTime.split('T')[1]}</td>
                  <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)}>{visit.checkInTime ? visit.checkInTime.split('T')[1] : "No Check In Time Yet"}</td>
                  <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)}>{visit.checkOutTime ? visit.checkOutTime.split('T')[1] : "No Check In Time Yet"}</td>
                  <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)} title={visit.organisationVisitDocuments.length > 0 ? "Files Uploaded" : "No File Uploaded"}>
                    {visit.organisationVisitDocuments.length > 0 ?
                      <Box sx={ITEM_PERFECT_INLINED} className="text-green-500 text-3xl pl-8" textAlign={"center"}><FileDownloadDoneIcon fontSize="inherit" /></Box>
                      : <Box sx={ITEM_PERFECT_INLINED} className="text-red-500 text-3xl pl-8" textAlign={"center"}><FileDownloadOffIcon /></Box>}
                  </td>
                  <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)}>
                    <span className={`${visit.checkOutTime ? "bg-green-400" : visit.checkInTime ? "bg-orange-400" : "bg-sky-400"} rounded-2xl text-white p-2 pt-1`}>
                      <Typography variant="caption">{visit.checkOutTime ? "Checked Out" : visit.checkInTime ? t('CHECKED_IN_BY_TEACHER') : t('NOT_CHECK_IN_BY_TEACHER')}</Typography>
                    </span>
                  </td>
                </tr>
                <VmVisitSlideDownCard
                  visit={visit}
                  visitChangeList={userStore.visitChangeList.data}
                  loadingVisitTable={userStore.loadingVisit}
                  fold={visitFold}
                  currentTraineeship={currentTraineeship}
                  colspan={10}
                />
                <Box sx={{ marginY: 1 }} />
              </>
            ))}
          />
        </>}

      {/* Create Form Modal */}
      <VmModal
        openModal={showCreateFormModal}
        title={"Submit Enrollment Form"}
        onClose={() => setShowCreateFormModal(false)}
        showButton={false}
      >
        <Typography sx={{ marginTop: -1, marginBottom: 2 }}>Create a new enroll form now?</Typography>
        <Box sx={ITEM_PERFECT_INLINED} className="justify-end">
          <Button
            variant="outlined"
            onClick={() => setShowCreateFormModal(false)}
            color="error"
          >
            Not Now
          </Button>
          <Button
            variant="outlined"
            onClick={() => createNewEnrolForm(currentStudent)}
            sx={{ marginLeft: 2 }}
            color="success"
          >
            Create Now
          </Button>
        </Box>
      </VmModal>

      <VmModal
        openModal={showModal}
        onClose={handleCloseModal}
        width={500}
        title={targetStudent ? t('UPDATE_A') + t('STUDENT_PROFILE') : t('ADD_A') + t('STUDENT_PROFILE')}
        buttonLabel={targetStudent ? t('CONFIRM_A') + t('UPDATE') : t('CONFIRM_A') + t('CREATION')}
        buttonLabelWithoutConfirm={targetStudent ? t('UPDATE_NOW') : t('CREATE_NOW')}
        onClickConfirmedButton={onActionToStudentByProcedure}
        buttonLoading={studentStore.loading || traineeshipStore.verifyingUSI}
        disabledCondition={title === "" || firstName === "" || lastName === "" || username === ""
          || password === "" || (!targetStudent && cPassword === "") || emailAddress === ""}
      >
        <div className="grid grid-cols-2 gap-4">
          <TextField
            value={title}
            label={t('TITLE') + ' *'}
            variant="outlined"
            sx={{ width: '100%' }}
            autoComplete={"off"}
            disabled={targetStudent && targetStudent.usi && targetStudent.usi !== ""}
            onChange={(value) => setTitle(value.target.value)}
          />
          <TextField
            value={username}
            label={t('USERNAME') + ' *'}
            variant="outlined"
            disabled={targetStudent}
            sx={{ width: '100%' }}
            autoComplete={"off"}
            onChange={(value) => setUsername(value.target.value)}
          />
          <TextField
            value={firstName}
            label={t('FIRST_NAME') + ' *'}
            variant="outlined"
            sx={{ width: '100%' }}
            autoComplete={"off"}
            disabled={targetStudent && targetStudent.usi && targetStudent.usi !== ""}
            onChange={(value) => setFirstName(value.target.value)}
          />
          <TextField
            value={lastName}
            label={t('LAST_NAME') + ' *'}
            variant="outlined"
            sx={{ width: '100%' }}
            autoComplete={"off"}
            disabled={targetStudent && targetStudent.usi && targetStudent.usi !== ""}
            onChange={(value) => setLastName(value.target.value)}
          />
          <TextField
            value={middleName}
            label={t('MIDDLE_NAME')}
            variant="outlined"
            sx={{ width: '100%' }}
            autoComplete={"off"}
            disabled={targetStudent && targetStudent.usi && targetStudent.usi !== ""}
            onChange={(value) => setMiddleName(value.target.value)}
          />
          <TextField
            value={birthDate}
            label={t('BOD') + ' *'}
            type="date"
            variant="outlined"
            sx={{ width: '100%' }}
            autoComplete={"off"}
            disabled={targetStudent && targetStudent.usi && targetStudent.usi !== ""}
            onChange={(value) => setBirthDate(value.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            value={password}
            label={t('PASSWORD') + ' *'}
            type="password"
            variant="outlined"
            sx={{ width: '100%' }}
            autoComplete={"off"}
            onChange={(value) => setPassword(value.target.value)}
          />
          <TextField
            value={cPassword}
            label={t('CONFIRM_PASSWORD') + ' *'}
            type="password"
            variant="outlined"
            sx={{ width: '100%' }}
            autoComplete={"off"}
            onChange={(value) => setCPassword(value.target.value)}
          />
          <TextField
            value={emailAddress}
            label={t('EMAIL') + ' *'}
            variant="outlined"
            sx={{ width: '100%' }}
            autoComplete={"off"}
            onChange={(value) => setEmailAddress(value.target.value)}
          />
        </div>
        {(targetStudent && targetStudent.emailAddress != emailAddress) &&
          <Typography color="red" variant="body2" mt={2}>
            WARNING: This trainee will become INACTIVE if email address is changed, this means the trainee will required to VALIDATE the account again using the new email.
          </Typography>}
        <Divider sx={{ marginY: 2 }} />
        <div className="flex">
          <TextField
            value={USI}
            label={t('USI Number')}
            variant="outlined"
            sx={{ width: 300 }}
            type="password"
            autoComplete={"off"}
            disabled={targetStudent && targetStudent.usi && targetStudent.usi !== ""}
            onChange={(value) => setUSI(value.target.value)}
            size="small"
          />
        </div>
      </VmModal>

      <VmModal
        openModal={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title={t('DELETE_A') + t('CONFIRMATION')}
        buttonLabel={t('CONFIRM_DELETE')}
        buttonLabelWithoutConfirm={t('DELETE_NOW')}
        buttonLoading={traineeshipStore.loading}
        onClickConfirmedButton={() => traineeshipStore.deleteEnrollForm(formId).then(() => {
          rootStore.notify('Enrollment Form' + t('DELETED_B'), 'success');
          studentStore.getStudentProfileById(currentStudent.id);
          handleCloseModal();
        }).catch(() => rootStore.notify(t('DELETE_FAILED'), 'error'))}
      >
        <Typography sx={{ marginBottom: 2 }}>{t('DELETE_CHECK')}: Enrollment Form?</Typography>
      </VmModal>

    </ContentLayout >
  )
});

export default TraineeshipOverviewPage;
