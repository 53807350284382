import { Typography } from "@mui/material"
import * as React from "react"
import useStores from "../hooks/use-stores"

// markup
const Heading = ({ children }) => {
  return (
    <div className="flex justify-between mt-4 mb-3">
      <Typography variant="h5" fontWeight={'bold'} marginTop={1}
        sx={{ borderLeftWidth: 8, borderColor: 'black', height: 'fit-content', paddingLeft: 2 }}>
        {children}
      </Typography>
    </div>
  )
}

export default Heading;
